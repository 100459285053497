@import '../../../assets/styles/variables';

.inputField {
  $block: &;
  width: 100%;

  &.-linkCopy {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__title {
    font: 700 14px/24px $OpenSans;
    color: $color-black;
    text-transform: capitalize;
  }

  &__input {
    padding: 16px 20px;
    border: 1px solid #000000;
    background: #ffffff;

    @media screen and (max-width: 768px) {
      padding: 12px 16px;
    }

    #{$block}.-linkCopy & {
      width: 100%;
    }

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }

    .MuiInputBase-input {
      font: 400 normal 16px/24px $OpenSans;
      color: $color-black-simple;
      padding: 0;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}

.checkbox-wrapper {
  position: relative;
  bottom: 10px;

  label {
    display: flex;
    align-items: center;
  }

  .checkbox-label {
    font: 700 14px/24px $OpenSans;
  }
  
  input[type='checkbox'] {
    /* removing default appearance */
    -webkit-appearance: none;
    appearance: none;
    /* creating a custom design */
    width: 1.6em;
    height: 1.6em;
    margin-right: 0.5em;
    border: 1px solid #000000;
    outline: none;
    cursor: pointer;
  }

  input:checked {
    background-color: #1e96fb;
    position: relative;
    border: 1px solid #1e96fb;
  }
  
  input:checked::before {
    content: "\2713";
    font-size: 20px;
    color: #fff;
    position: absolute;
    right: 3px;
    top: -1px;
  }

  .checkbox-wrapper input[type="checkbox"]:disabled {
    border-color: #c0c0c0;
    background-color: #c0c0c0;
  }
  
  .checkbox-wrapper input[type="checkbox"]:disabled + span {
    color: #c0c0c0;
  }
  
  .checkbox-wrapper input[type="checkbox"]:focus {
    box-shadow: 0 0 20px #1e96fb;
  }
}
