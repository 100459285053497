@import "../../../../assets/styles/variables";

.table.-reportInfo {
  padding-top: 60px;
  
  @media screen and (max-width: 768px){
    padding-top: 40px;
  }
  
  &__title {
    font: 800 24px/32px $OpenSans;
    color: $color-black-simple;
    margin-bottom: 40px;

    @media screen and (max-width: 768px){
      margin-bottom: 20px;
    }
  }
  
  .tableContainer {
    
    .MuiTableHead-root {
      
      .MuiTableRow-root {
        border-top: none;
        
        .MuiTableCell-root {
          font: 700 14px/24px $OpenSans;
          color: $color-black;
          white-space: nowrap;
          text-transform: capitalize;
          padding: 6px 16px;
          border-bottom: 2px solid $color-black-simple;
        }
      }
    }
    
    .MuiTableBody-root {      
      .MuiTableRow-root {
        &:hover {
          background-color: darken(#FDFDFD, 3%);
        }
        .MuiTableCell-root {
          font: 600 14px/24px $OpenSans;
          color: $color-black-simple;
          padding: 11px 16px;
          border-bottom: 1px solid #F2F1EF;
          
          span {
            font-weight: 400;
            font-size: 12px;
          }
        }
      }
    }
  }
}
