.rdw-editor-toolbar {
  border: 1px solid #000000 !important;
  border-radius: 0px !important;
  margin-bottom: 0px !important;
}

.draftEditorCustom {
  max-height: 100px;
  overflow-y: scroll;
  border: 1px solid black;
  padding: 20px 5px;

  .public-DraftStyleDefault-block {
    margin: 0 !important;
  }
}

.draftEditorCustomReadonly {
  max-height: 100px;
}