.formContainer {
  padding-top: 36px;

  @media screen and (max-width: 768px){
    padding-top: 20px;
  }
  
  &__row {
    margin-bottom: 20px;
    display: grid;
    grid-gap: 20px 24px;
    align-items: flex-start; //flex-end;

    &.alignTop {
      align-items: flex-end !important;
    }

    &.mb-0 {
      margin-bottom: 0 !important;
    }
    
    &.-grid-4 {
      grid-template-columns: repeat(4, 1fr);
      
      @media screen and (max-width: 992px){
        grid-template-columns: repeat(3, 1fr);
      }
      @media screen and (max-width: 768px){
        grid-gap: 8px 16px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 576px){
        margin-bottom: 8px;
        grid-gap: 8px 16px;
        grid-template-columns: 1fr;
      }
    }
    &.-grid-3 {
      grid-template-columns: repeat(3, 1fr);
      
      @media screen and (max-width: 768px){
        grid-gap: 8px 16px;
        grid-template-columns: repeat(2, 1fr);
      }
      @media screen and (max-width: 576px){
        margin-bottom: 8px;
        grid-gap: 8px 16px;
        grid-template-columns: 1fr;
      }
    }
    &.-grid-2 {
      grid-template-columns: repeat(2, 1fr);
      
      @media screen and (max-width: 768px){
        grid-gap: 8px 16px;
        grid-template-columns: 1fr;
      }
      
      .formContainer__column & {
        @media screen and (max-width: 992px){
          grid-gap: 8px 16px;
          grid-template-columns: 1fr;
        }
      }
    }
  }
  
  &__title {
    font: 800 20px/32px $OpenSans;
    color: $color-black-simple;
    margin-top: 40px;
    margin-bottom: 16px;
    
    &.mt-0 {
      margin-top: 0;
    }
  }
}