@import "../../../assets/styles/variables";

.footerContainer {
  background: $color-blue-main;
}

.footerView {
  padding: 20px 20px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 20px;
  width: 100%;
  max-width: 1264px;
  box-sizing: border-box;

  @media screen and (max-width: 768px){
    padding: 12px 16px;
  }
  @media screen and (max-width: 400px){
    flex-direction: column;
  }
  
  .footerNavigation {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;

    @media screen and (max-width: 400px){
      flex-direction: column;
    }
    
    &__item {
      display: flex;
      align-items: center;
      
      &:last-of-type {
        &:after {
          display: none;
        }
      }
      
      &:after {
        font: 400 14px/24px $OpenSans;
        color: #E5E5E5;
        margin: 0 12px;
        content: '/';
        display: block;

        @media screen and (max-width: 400px){
          display: none;
        }
      }
      
      &.state_active {
        pointer-events: none;
        .footerNavigation__link {
          font-weight: 600;
          color: #fff;
        }
      }
    }
    
    &__link {
      font: 400 14px/24px $OpenSans;
      color: #E5E5E5;

      @media screen and (max-width: 400px){
        font-size: 16px;
        line-height: 28px;
      }
    }
  }
  
  .footerLogo {
    display: block;
    width: 100%;
    max-width: 106px;
    
    &__img {
      display: block;
      width: 100%;
    }
  }
}