.pageHeader {
  $block: &;
  padding-bottom: 28px;
  border-bottom: 4px solid $color-black-simple;
  
  @media screen and (max-width: 768px){
    padding-bottom: 0;
    border-bottom: none;
  }
  
  &__rowTitle {
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 12px;

    @media screen and (max-width: 768px){
      flex-wrap: wrap;
      gap: 20px
    }
  }
  
  &__rowSubTitle {
    font: 600 24px/36px $OpenSans;
    color: $color-yellow;
    margin-top: 8px;

    @media screen and (max-width: 992px){
      font-size: 20px;
      line-height: 28px;
    }
    @media screen and (max-width: 768px){
      padding-bottom: 16px;
      width: 100%;
      border-bottom: 4px solid $color-black-simple;
    }
  }
  
  &__title {
    font: 800 32px/44px $OpenSans;
    color: $color-black-simple;

    @media screen and (max-width: 768px){
      font-size: 24px;
      line-height: 28px;
      padding-bottom: 16px;
      width: 100%;
      border-bottom: 4px solid $color-black-simple;
    }
    
    &.-detailsStyle {
      @media screen and (max-width: 768px){
        border-bottom: none !important;
      }
    }
  }
  
  &__tabList {
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: center;
    gap: 12px 0;

    @media screen and (max-width: 768px){
      width: 100%;
      overflow: auto;
    }
  }
  
  &__tabItem {
    position: relative;
    margin-right: 63px;

    @media screen and (max-width: 992px){
      margin-right: 28px;
    }
    
    &:last-of-type {
      margin-right: 0;
      &:after {
        display: none;
      }
    }
    
    &:after {
      position: absolute;
      top: 50%;
      right: -33px;
      margin-top: -14px;
      content: '';
      display: block;
      width: 2px;
      height: 28px;
      background: #000;

      @media screen and (max-width: 992px){
        right: -14px;
      }
    }
  }
  
  &__tabLink {
    font: 800 32px/44px $OpenSans;
    color: #E5E5E5 !important;
    text-transform: capitalize;
    transition: .4s ease-in-out;
    
    @media screen and (max-width: 992px){
      font-size: 24px;
      line-height: 36px;
    }
    @media screen and (max-width: 768px){
      white-space: nowrap;
    }
    
    &:hover {
      color: rgba(0, 0, 0, 0.4) !important;
    }
    
    #{$block}__tabItem.state_active & {
      color: #000 !important;
      pointer-events: none;
    }
  }
  
  &__btn {
    font: 600 12px/16px $OpenSans !important;
    color: #fff;
    padding: 10px 40px !important;
    display: flex !important;
    align-items: center;
    justify-content: center;
    border-radius: 28px !important;
    background: $color-gray !important;
    
    &.-desktop {
      @media screen and (max-width: 768px){
        display: none !important;
      }
    }
    &.-mobile {
      display: none !important;
      @media screen and (max-width: 768px){
        margin-top: 20px;
        display: flex !important;
      }
    }
    
    &Icon {      
      &.-plus {
        margin-right: 4px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/icons/arrow-plus_ic.svg") center no-repeat;
      }    
      &.-back {
        margin-right: 4px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/icons/arrow-back_ic.svg") center no-repeat;
      }
      &.-double-arrow {
        margin-right: 4px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/icons/double-arrow-icon.svg") center no-repeat;
        transform: rotate(90deg);
      }
      &.-double-arrow-down {
        margin-right: 4px;
        display: block;
        width: 12px;
        height: 12px;
        background: url("../../../assets/images/icons/double-arrow-icon.svg") center no-repeat;
      }
    }
  }
}