@import '../../../assets/styles/variables';
.formContainer_createUser {

  .userManagementBtnsContainer {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .formBlock {
    width: 30%;
    margin-bottom: 30px;

    @media screen and (max-width: 768px) {
      width: 100%;
    }

    &__title {
      font: 700 18px/24px $OpenSans;
      margin-bottom: 15px;
    }

    .MuiFormControl-root {
      margin-bottom: 10px !important;
    }
  }

  .formBlock_permissions {
    width: 100%;

    .MuiFormGroup-root {
      flex-wrap: nowrap;

      .MuiTypography-root {
        font: 600 14px/24px $OpenSans;
      }

      @media screen and (max-width: 768px) {
        flex-wrap: wrap;
      }
    }
  }

  .saveUserBtn {
    background-color: #1e96fb !important;
    margin-right: 10px;
  }

  .radioRestLabel {
    font-style: italic;
    color: #878787;
    display: block;
  }

  .btnsWrapper {
    justify-content: flex-start;
  }

  .deleteBtn {
    background-color: #f55757 !important;
  }

  .formBlockInline {
    display: inline-flex;
    width: 100%;

    @media screen and (max-width: 768px) {
      display: block;
    }

    .activeBtnWrap {
      margin-left: auto !important;

      .activeBtn {
        width: 260px;
        white-space: nowrap;
        margin-top: 0 !important;
        background: $color-red !important;

        @media screen and (max-width: 768px) {
          margin-top: 30px !important;
        }

      }
    }

  }
}