.simpleBtn {
  font: 600 16px/22px $OpenSans !important;
  color: #fff;
  text-transform: uppercase;
  margin-top: 32px !important;
  padding: 17px 110px !important;
  border-radius: 28px !important;
  background: $color-gray !important;
  &.-green {
    background: $color-green !important;
  }
  &.-blue {
    background: $color-blue-main !important;
  }
  @media screen and (max-width: 768px){
    padding: 13px 90px !important;
    margin-top: 20px !important;
  }

  @media screen and (max-width: 515px){
    padding: 13px 20px !important;
    width: 100%;
  }
  
  &.-border {
    padding-left: 56px !important;
    padding-right: 56px !important;
    color: $color-gray;
    border: 2px solid $color-gray !important;
    background: transparent !important;

    @media screen and (max-width: 768px){
      padding: 13px 70px !important;
      margin-top: 20px !important;
    }
    @media screen and (max-width: 400px){
      padding: 13px 20px !important;
      width: 100%;
    }
  }

  &.-ml-10 {
    @media screen and (min-width: 510px){
      margin-left: 10px;
    }
  }
}