.stepContent {
  
  &__questionWrapper {
    margin-top: 40px;
    
    @media screen and (max-width: 768px){
      margin-top: 32px;
    }
    
    &.mt-0 {
      margin-top: 0;
    }

    .textareaField {
      &__textarea {
        min-height: auto !important;

        @media screen and (max-width: 768px){
          min-height: 70px !important;
        }
      }
    }
  }
  
  &__discussionWrapper {
    margin-top: 12px;

    @media screen and (max-width: 768px){
      margin-top: 8px;
    }
  }
  
  &__discussionLabel {
    font: 700 14px/24px $OpenSans;
    color: $color-black;

    @media screen and (max-width: 768px){
      line-height: 20px;
    }
  }
  
  &__discussionContent {
    padding: 16px 20px;
    border: 1px solid #E5E5E5;

    @media screen and (max-width: 768px){
      padding: 12px;
    }
  }
  
  &__discussionTitle {
    font: 700 16px/24px $OpenSans;
    color: $color-black-simple;
    margin-bottom: 10px;

    @media screen and (max-width: 768px){
      font-size: 14px; 
    }
  }
  
  &__discussionText {
    font: 400 16px/24px $OpenSans;
    color: $color-black-simple;

    @media screen and (max-width: 768px){
      font-size: 14px;
    }
  }
  
  &__discussionHiddenText {
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
    overflow: hidden;
    display: block;
    
    &.state_show {
      transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
      height: auto;
      max-height: 9999px;
    }
  }
  
  &__discussionMoreLink {
    font: 700 16px/24px $OpenSans;
    color: $color-yellow;
    margin-left: 10px;

    @media screen and (max-width: 768px){
      font-size: 14px;
    }
    
    &:hover {
      text-decoration: underline;
    }
    
    &.state_hide {
      display: none;
    }
  }
  
  &__btnWrapper {
    margin-top: 32px;
    display: flex;
    align-items: center;
    gap: 18px;
    flex-wrap: wrap;

    @media screen and (max-width: 576px){
      margin-top: 0;
    }
  }
  
  &__btn {
    margin-top: 0 !important;
    padding-left: 40px !important;
    padding-right: 40px !important;
    
    @media screen and (max-width: 576px){
      width: 100% !important;
    }
  }
}