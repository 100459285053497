* {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
  outline: none;
}

a {
  text-decoration: none;
  transition: 0.3s ease-in-out;
}

button {
  transition: 0.3s ease-in-out;
  border: none;
  font-family: 'Epilogue', sans-serif;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
}

body {
  font-family: 'Epilogue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

a.tox-promotion-link, span.tox-statusbar__branding {
  display: none !important;
}