@import '../../../assets/styles/variables';

.signInContainer {
  padding: 0 16px;
  width: 100%;
  min-height: 100vh;
  background: url('../../../assets/images/auth_bg.jpg') rgba(0, 0, 0, 0.4);
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  background-blend-mode: multiply;

  @media screen and (max-width: 768px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  @media screen and (max-height: 800px) {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.signInView {
  padding-top: 160px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%;
  max-width: 600px;

  @media screen and (max-width: 768px) {
    padding-top: 0;
  }
  @media screen and (max-height: 800px) {
    padding-top: 0;
  }

  .mainLogo {
    margin-bottom: 40px;
    display: block;
    max-width: 100%;

    @media screen and (max-width: 768px) {
      margin-bottom: 28px;
      max-width: 184px;
    }
  }

  .authForm {
    padding: 48px 104px 68px;
    width: 100%;
    max-width: 600px;
    background: #fff;

    @media screen and (max-width: 768px) {
      padding: 32px 32px 48px;
    }
    @media screen and (max-width: 400px) {
      padding: 32px 18px 48px;
    }

    &__title {
      font: 800 32px/44px $OpenSans;
      color: $color-black-simple;
      text-align: center;
      margin-bottom: 48px;

      @media screen and (max-width: 768px) {
        font-size: 24px;
        line-height: 33px;
        margin-bottom: 23px;
      }
    }

    &__row {
      margin-top: 5px;
      display: flex;
      align-items: center;
      justify-content: space-between;

      @media screen and (max-width: 768px) {
        margin-top: 0;
      }
      @media screen and (max-width: 400px) {
        flex-direction: column;
        gap: 12px;
      }
    }

    &__link {
      font: 400 16px/20px $OpenSans;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }

    &__btn {
      font: 600 16px/22px $OpenSans !important;
      text-transform: uppercase;
      text-align: center;
      margin: 25px auto 0 !important;
      padding: 17px 92px !important;
      display: flex !important;
      border-radius: 28px !important;
      box-shadow: none !important;

      @media screen and (max-width: 768px) {
        padding: 13px 108px !important;
      }
      @media screen and (max-width: 400px) {
        padding-left: 20px !important;
        padding-right: 20px !important;
        width: 100%;
      }
    }
  }

  .authField {
    padding: 16px 20px 16px 17px;
    //margin-bottom: 20px;////
    width: 100%;
    border: 1px solid #000000;
    border-radius: 0;

    &:before {
      display: none !important;
    }

    &:after {
      display: none !important;
    }

    .MuiInputAdornment-root {
      padding-right: 16px;
      margin: 0;
      height: auto;
      border-right: 1px solid rgba($color-black-simple, 0.1);
    }

    .password-eye {
      border: none !important;
      padding: 0 !important;
      cursor: pointer !important;
    }

    .MuiInputBase-input {
      padding: 0 0 0 15px;
    }

    &.Mui-error {
      border: solid 1px red !important;
      margin-bottom: 4px !important;

      + .MuiFormHelperText-root {
        font-size: 12px !important;
        color: #d32f2f !important;
        border-style: none !important;
        white-space: pre-wrap !important;
        margin-bottom: 16px !important;
        margin-top: -3px !important;
      }
    }
  }

  .authCheckbox {
    .MuiTypography-root {
      font: 400 16px/20px $OpenSans;
      color: $color-black-simple;

      @media screen and (max-width: 768px) {
        font-size: 14px;
      }
    }
  }
}
