.topBanner {
  display: block;
  width: 100%;
  
  &__img {
    display: block;
    width: 100%;
    height: 240px;
    object-fit: cover;

    @media screen and (max-width: 768px){
      height: 128px;
    }
    
    &.-desktop {
      @media screen and (max-width: 576px){
        display: none;
      }
    }
    &.-mobile {
      display: none;
      @media screen and (max-width: 576px){
        display: block;
      }
    }
  }
}