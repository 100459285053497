.packageCheckboxList {
  $block: &;
  padding: 24px 0 96px;
  margin: 0;
  list-style: none;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 24px;

  @media screen and (max-width: 1200px){
    grid-template-columns: 1fr;
    grid-gap: 64px;
  }
  @media screen and (max-width: 576px){
    padding-bottom: 76px;
  }
  
  &__item {
    display: flex;
    flex-direction: column;
    border-radius: 20px;

    @media screen and (max-width: 1200px){
      margin-left: auto;
      margin-right: auto;
      width: 100%;
      max-width: 440px;
    }
    
    &Header {
      position: relative;
      z-index: 2;
      padding: 23px 35px 37px;
      display: flex;
      justify-content: center;
      align-items: center;
      min-height: 180px;
      border-radius: 20px 20px 0 0;
      background: $color-blue-main;
      
      @media screen and (max-width: 576px){
        padding: 20px 12px 20px;
        min-height: 136px;
      }
    }
    
    &Title {
      font: 600 24px/32px $OpenSans;
      color: $color-white-simple;
      text-align: center;

      @media screen and (max-width: 576px){
        font-size: 20px;
        line-height: 28px;
      }
    }
    
    &SubTitle {
      position: absolute;
      left: 50%;
      bottom: -20px;
      transform: translateX(-50%);
      font: 600 12px/20px $OpenSans;
      color: $color-black-simple;
      text-align: center;
      text-transform: uppercase;
      padding: 10px 36px;
      border-radius: 26px;
      background: #FFFFFF;
      box-shadow: 0 14px 40px rgba(0, 0, 0, 0.05);

      @media screen and (max-width: 576px){
        width: 100%;
        max-width: 184px;
      }
      
      #{$block}__item.checked & {
        color: $color-blue-main;
      }
    }
    
    &Content {
      position: relative;
      padding: 42px 24px 57px;
      display: flex;
      flex-direction: column;
      align-items: center;
      flex: 1;
      border-radius: 0 0 20px 20px;
      background: #F9F9F9;

      #{$block}__item.checked & {
        background: $color-yellow;
      }

      .inputField {
        position: absolute;
        left: 50%;
        bottom: -45px;
        transform: translateX(-50%);
        width: auto;
        
        &:before {
          position: absolute;
          left: 50%;
          bottom: 14px;
          transform: translateX(-50%);
          content: '';
          display: block;
          width: 60px;
          height: 60px;
          border-radius: 50%;
          background: #fff;
        }
        
        .MuiSvgIcon-root {
          &[data-testid="RadioButtonUncheckedIcon"] {
            width: 72px;
            height: 72px;
            fill: #E5E5E5;
            border-radius: 50%;
          }
          &[data-testid="RadioButtonCheckedIcon"] {
            width: 72px;
            height: 72px;
            fill: $color-yellow;
            border-radius: 50%;
          }
        }
      }
    }
    
    &List {
      padding: 0;
      margin: 0 0 30px;
      list-style: none;
    }
    
    &Include {
      position: relative;
      font: 600 16px/32px $OpenSans;
      color: $color-black-simple;
      padding-left: 42px;
      margin-bottom: 5px;

      #{$block}__item.checked & {
        color: $color-white-simple;
      }
      
      &:last-of-type {
        margin-bottom: 0;
      }
      
      &Icon {
        position: absolute;
        top: 4px;
        left: 0;
        display: block;
        width: 24px;
        height: 24px;

        #{$block}__item.checked & {
          path {
            fill: #fff;
          }
        }
      }
    }
  }
  
  &__price {
    font: 800 48px/36px $OpenSans;
    color: $color-black;
    text-align: center;
    margin-top: auto;
    margin-bottom: 0;

    #{$block}__item.checked & {
      color: $color-white-simple;
    }

    &Text {
      font: 600 20px/28px $OpenSans;
      color: $color-black-simple;
      text-transform: uppercase;
      text-align: center;

      #{$block}__item.checked & {
        color: $color-white-simple;
      }
    }
  }
}