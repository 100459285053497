@import "../../../assets/styles/variables";

.textareaField {
  width: 100%;

  &__title {
    font: 700 14px/24px $OpenSans;
    color: $color-black;
    text-transform: capitalize;
  }

  &__textarea {
    padding: 16px 20px;
    min-height: 112px;
    border: 1px solid #000000;
    background: #FFFFFF;
    resize: none;

    @media screen and (max-width: 768px){
      padding: 12px 16px;
    }

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }

    .MuiInputBase-input {
      font: 400 normal 16px/24px $OpenSans;
      color: $color-black-simple;
      padding: 0;

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
}