.titleAndBtnContainer {
  display: flex;
  justify-content: space-between;

  .notesContainer {
    margin-top: 10px;
    padding: 10px 20px;
    border-radius: 5px;
    box-shadow: 2px 35px 60px 40px rgb(0 0 0 / 5%);
    width: 100%;
  }
}

.customerNotesBtn {
  background: #1e96fb !important;
}

.customerNotesBtnWrapper {
  display: block;
  max-width: 500px;

  .customerNotesBtn {
    margin-left: auto;
  }
}

.rdt_TableCol_Sortable > div {
  white-space: break-spaces !important;
}

.rdt_TableCell > div {
  white-space: break-spaces !important;
}