.thxPage {
  padding-top: 30px;
  
  &__title {
    font: 700 24px/1.4 $OpenSans;
    color: $color-black;
    margin-bottom: 24px;
  }
  
  &__text {
    font: 400 16px/1.2 $OpenSans;
    color: $color-black-simple;
    margin-bottom: 18px;
  }
  
  &__subTitle {
    font: 700 20px/1.2 $OpenSans;
    color: $color-black-simple;
    margin-bottom: 18px;
  }
  
  &__list {
    padding: 0 0 24px;
    margin: 0 0 24px;
    list-style: none;
    width: 100%;
    border-bottom: 2px solid $color-black-simple;
  }
  
  &__item {
    position: relative;
    font: 400 16px/1.2 $OpenSans;
    color: $color-black-simple;
    padding-left: 20px;
    margin-bottom: 8px;

    &:last-of-type {
      margin-bottom: 0;
    }
    
    &:before {
      position: absolute;
      top: 6px;
      left: 0;
      content: '';
      display: block;
      width: 5px;
      height: 5px;
      border-radius: 50%;
      background: $color-blue-main;
    }
  }
  
  &__totalWrapper {
    margin-bottom: 30px;
    display: flex;
    align-items: center;
    gap: 24px;
  }
  
  &__totalTitle {
    font: 700 18px/1.2 $OpenSans;
    color: $color-black-simple;
  }
  
  &__totalValue {
    font: 700 18px/1.2 $OpenSans;
    color: $color-black-simple;
  }
  
  &__endText {
    font: 700 italic 14px/1.2 $OpenSans;
    color: $color-yellow;
  }
}