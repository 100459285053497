@import "../../../assets/styles/variables";

.UploaderInputField {
  $block: &;
  width: 100%;

//border: 1px solid red !important;


  &__title {
    font: 700 14px/24px $OpenSans;
    color: $color-black;
    text-transform: capitalize;
  }
  
  &__input {
    padding: 16px 20px;
    margin-right: 10px;
    border: 1px solid #000000;
    background: #FFFFFF;
    width: 100%;

    &.error {
      border-color: $color-error;
    }

    &.MuiInputBase-readOnly {
      background: #f1f1f1 !important;
    }

    &>input[readonly] {
      color: blue;
      font-weight: bold;
    }

    @media screen and (max-width: 768px){
      padding: 12px 16px;
    }

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }
    
    .MuiInputBase-input {
      width: 0;
      font: 400 normal 16px/24px $OpenSans;
      color: $color-black-simple;
      padding: 0;
      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
}

.uploader {
  display: flex;
}

.uploadButton {
  font: 600 14px/24px $OpenSans !important;
  width: 150px;
}

.uploaderLink {
  width: 100%;
  font: 500 normal 16px $OpenSans;
  &:hover {
    font-weight: 600;
  }
}


