@import "../../../assets/styles/variables";

.headerContainer {
  background: $color-white-simple;
}

.headerView {
  padding-left: 40px;
  padding-right: 40px;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 1520px;

  @media screen and (max-width: 768px){
    padding: 12px 16px;
  }
}

.headerLogo {
  
  &__img {
    display: block;
    max-width: 128px;

    @media screen and (max-width: 768px){
      max-width: 96px;
    }
  }
}

.headerNavigation {
  $block: &;
  padding: 0;
  margin: 0;
  list-style: none;
  display: flex;
  z-index: 1;
  &__item {
    button {
      display: none;
      width: 100%;
      padding: 18px 20px;
      text-transform: inherit;
      font: 600 14px/28px "Open Sans", sans-serif;
    }
  }

  @media screen and (max-width: 768px){
    display: none;
  }


  //@media screen and (max-width: 768px){
  //  position: absolute;
  //  top: -50%;
  //  left: 0;
  //  flex-direction: column;
  //  width: 100%;
  //  background: #fff;
  //  box-shadow: 0px 12px 40px rgba(0, 0, 0, 0.05);
  //  transition: .4s ease-in-out;
  //
  //  &__item {
  //    button {
  //      display: block;
  //    }
  //  }
  //}
  
  &.state_open {
    top: 60px;
  }
  
  &__link {
    position: relative;
    font: 600 16px/28px $OpenSans;
    color: #6C757D !important;
    padding: 26px 40px;
    display: block;
    border-top: 2px solid transparent;

    @media screen and (max-width: 768px){
      font-size: 14px;
      line-height: 24px;
      text-align: center;
      padding: 18px 20px; 
      border-top: none;
      border-bottom: 2px solid transparent;
    }

    &:hover {
      &:before {
        width: 50%;
      }
      &:after {
        width: 50%;
      }
    }
    
    &:before {
      position: absolute;
      top: -2px;
      right: 50%;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $color-blue-main;
      transition: .4s ease-in-out;

      @media screen and (max-width: 768px){
        top: auto;
        bottom: -2px;
      }
    }
    &:after {
      position: absolute;
      top: -2px;
      left: 50%;
      content: '';
      display: block;
      width: 0;
      height: 2px;
      background: $color-blue-main;
      transition: .4s ease-in-out;

      @media screen and (max-width: 768px){
        top: auto;
        bottom: -2px;
      }
    }
    
    #{$block}__item.state_active & {
      border-top: 2px solid $color-blue-main;

      @media screen and (max-width: 768px){
        border-top: none;
        border-bottom: 2px solid $color-blue-main;
      }
      &:before {
        display: none;
      }
      &:after {
        display: none;
      }
    }
  }
}

.headerHamburger {
  $block: &;
  display: flex;
  justify-content: flex-end;
  width: 100%;
  max-width: 128px;
  position: relative;
  z-index: 1;
  
  &__btn {
    position: relative;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 24px;
    height: 18px;
    background: transparent;
    cursor: pointer;

    &.state_active {
      &:before {
        top: 8px;
        transform: rotate(45deg);
      }
      &:after {
        bottom: 8px;
        transform: rotate(-45deg);
      }
    }

    &:before {
      position: absolute;
      top: 0;
      left: 0;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background: $color-blue-main;
      transition: .4s;
    }
    &:after {
      position: absolute;
      left: 0;
      bottom: 0;
      content: '';
      display: block;
      width: 100%;
      height: 2px;
      border-radius: 1px;
      background: $color-blue-main;
      transition: .4s;
    }
    
    &Line {
      display: block;
      align-self: flex-end;
      width: 20px;
      height: 2px;
      border-radius: 1px;
      background: $color-blue-main;
      transition: .4s;

      #{$block}__btn.state_active & {
        background: transparent;
      }
      
      #{$block}__btn:hover & {
        width: 100%;
      }
    }
  }

  &__menuHeader {
    margin-left: 8px;
    margin-bottom: 10px;
    color: black;
    font-size: 22px;
    font-weight: 800;
  }

  &__menuMobile {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    font: 600 14px/28px "Open Sans", sans-serif;
    margin-bottom: 20px;

    &>button {
      white-space: nowrap;
      font: 700 14px/28px "Open Sans", sans-serif;
      padding: 2px 8px 2px 8px;
    }

    @media screen and (min-width: 768px){
      display: none;
    }
  }


  &__menu {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    position: absolute;
    top: 25px;
    color: #1e96fb;
    padding: 20px 10px 10px 10px;
    background: #fff;
    box-shadow: 30px 35px 30px 40px rgb(0 0 0 / 20%);
    transition: 0.4s ease-in-out;
    font: 600 14px/28px "Open Sans", sans-serif;

    &>button {
      white-space: nowrap;
      font: 700 14px/28px "Open Sans", sans-serif;
      padding: 2px 8px 2px 8px;

      &:last-child {
        margin-top: 12px;
        &>img {
          margin-left: -15px;
          margin-bottom: 1px;
        }
      }
    }
  }

}