.pageContainer {
  flex: 1;
  background: $color-white-simple;
  
  &__view {
    padding: 80px 20px 96px;
    margin: 0 auto;
    width: 100%;
    max-width: 1264px;
    box-sizing: border-box;
    
    @media screen and (max-width: 768px){
      padding: 32px 16px 56px;
    }
  }
}