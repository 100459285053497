.pageReportInfo {
  padding-top: 20px;
  
  &__header {
    padding: 16px 0;
    display: flex;
    align-items: center;
    justify-content: space-between;
    gap: 24px;
    
    @media screen and (max-width: 768px){
      flex-direction: column-reverse;
      align-items: flex-start;
      gap: 12px;
    }
  }
  
  &__selectYear {
    width: 100%;
    max-width: 288px;
  }
  
  &__btn {
    
  }
  
  &__content {
    padding: 16px 0;
  }
  
  &__item {
    margin-bottom: 12px;
    display: flex;
    gap: 24px;
    
    &:last-of-type {
      margin-bottom: 0;
    }

    &Title {
      font: 600 16px/1.4 $OpenSans;
      color: $color-black;
      display: flex;
      flex-direction: column;
      width: 100%;
      max-width: 300px;

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
    
    &Value {
      font: 600 16px/1.4 $OpenSans;
      color: $color-black;

      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
    
    &SubTitle {
      font: 600 italic 12px/1.4 $OpenSans;
      color: $color-black;
    }
  }
}