@import "../../../assets/styles/variables";

.inputField {
  $block: &;
  width: 100%;

  &.-linkCopy {
    display: flex;
    align-items: center;
    flex-direction: row;
  }

  &__title {
    font: 700 14px/24px $OpenSans;
    color: $color-black;
    text-transform: capitalize;
  }
  
  &__input {
    padding: 16px 20px;
    border: 1px solid #000000;
    background: #FFFFFF;

    &.-file {
      padding-top: 0;
      padding-bottom: 0;
      .MuiInputBase-input {
        display: none;
        //height: 31px !important;
      }
    }

    &.MuiInputBase-readOnly {
      background: #f1f1f1 !important;
    }

    &>input[readonly] {
      color: blue;
      font-weight: bold;
    }

    @media screen and (max-width: 768px){
      padding: 12px 16px;
    }
    
    #{$block}.-linkCopy & {
      width: 100%;
    }

    &:before {
      display: none !important;
    }
    &:after {
      display: none !important;
    }
    
    .MuiInputBase-input {
      font: 400 normal 16px/24px $OpenSans;
      color: $color-black-simple;
      padding: 0;
      //height: 25px !important;
      @media screen and (max-width: 768px){
        font-size: 14px;
      }
    }
  }
}